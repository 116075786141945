// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Empty.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Empty.tsx");
  import.meta.hot.lastModified = "1720795858864.4258";
}
// REMIX HMR END

import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import * as React from 'react';
import { useMemo } from 'react';
import { cn } from '~/utils/cn';

// break-words is supposed to handle this scenario according to TW
// @see https://tailwindcss.com/docs/word-break#break-words
// but it doesn't work in my browser. word-break is not included in TW for some
// reason, but it does work. I'm including both for safety and best browser support
const wordBreakStyle = {
  wordBreak: 'break-word'
};
const emptyVariants = cva('flex flex-col items-center justify-center text-center rounded border border-dashed py-6 px-4 text-sm overflow-hidden break-words', {
  variants: {
    variant: {
      default: 'border-neutral-300 text-neutral-500',
      error: 'border-red-700 text-red-800',
      warning: 'border-amber-600 text-amber-700'
    },
    size: {
      default: 'h-full',
      sm: 'h-60',
      md: 'h-80',
      lg: 'h-96'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
const Empty = _s(React.forwardRef(_c = _s(({
  className,
  style,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  _s();
  const Comp = asChild ? Slot : 'div';
  const compStyle = useMemo(() => ({
    ...wordBreakStyle,
    ...style
  }), [style]);
  return <Comp style={compStyle} className={cn(emptyVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
}, "InqdCO7RSGuISRQ+g+EH7VLkGR8=")), "InqdCO7RSGuISRQ+g+EH7VLkGR8=");
_c2 = Empty;
Empty.displayName = 'Empty';
export { Empty, emptyVariants };
var _c, _c2;
$RefreshReg$(_c, "Empty$React.forwardRef");
$RefreshReg$(_c2, "Empty");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;